@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	:root {
		--background: 0 0% 100%;
		--foreground: 222.2 84% 4.9%;
		--card: 0 0% 100%;
		--card-foreground: 222.2 84% 4.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 222.2 84% 4.9%;
		--primary: 201 96% 32%;
		--primary-foreground: 210 40% 98%;
		--secondary: 210 40% 96.1%;
		--secondary-foreground: 222.2 47.4% 11.2%;
		--muted: 210 40% 96.1%;
		--muted-foreground: 215.4 16.3% 46.9%;
		--accent: 210 40% 96.1%;
		--accent-foreground: 222.2 47.4% 11.2%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 210 40% 98%;
		--border: 214.3 31.8% 91.4%;
		--input: 214.3 31.8% 91.4%;
		--ring: 201 96% 32%;
		--radius: 0.5rem;
	}

	.dark {
		--background: 222.2 84% 4.9%;
		--foreground: 210 40% 98%;
		--card: 222.2 84% 4.9%;
		--card-foreground: 210 40% 98%;
		--popover: 222.2 84% 4.9%;
		--popover-foreground: 210 40% 98%;
		--primary: 201 96% 32%;
		--primary-foreground: 222.2 47.4% 11.2%;
		--secondary: 217.2 32.6% 17.5%;
		--secondary-foreground: 210 40% 98%;
		--muted: 217.2 32.6% 17.5%;
		--muted-foreground: 215 20.2% 65.1%;
		--accent: 217.2 32.6% 17.5%;
		--accent-foreground: 210 40% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 210 40% 98%;
		--border: 217.2 32.6% 17.5%;
		--input: 217.2 32.6% 17.5%;
		--ring: 212.7 26.8% 83.9%;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
		font-feature-settings:
			'rlig' 1,
			'calt' 1;
	}
}

@keyframes text-shine {
	0% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes slide {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}

@keyframes wave {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

.animate-text-shine {
	background-size: 200% auto;
	animation: text-shine 3s ease-in-out infinite alternate;
}

.animate-slide {
	animation: slide 20s linear infinite;
}

.animate-wave {
	animation: wave 20s linear infinite;
}

.animate-wave-slow {
	animation: wave 30s linear infinite;
}

.perspective-1000 {
	perspective: 1000px;
}

@keyframes pulse-slow {
	0%,
	100% {
		opacity: 0.3;
	}
	50% {
		opacity: 0.4;
	}
}

@keyframes grid-slide {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-24px);
	}
}

.animate-pulse-slow {
	animation: pulse-slow 4s ease-in-out infinite;
}

.animate-grid {
	animation: grid-slide 20s linear infinite;
}

.delay-1000 {
	animation-delay: 1000ms;
}

.shadow-3xl {
	--tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
	--tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
	box-shadow:
		var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.drop-shadow-sm {
	filter: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05));
}

.drop-shadow-md {
	filter: drop-shadow(0 2px 2px rgb(0 0 0 / 0.1));
}

@layer base {
	* {
		@apply border-border outline-ring/50;
	}
	body {
		@apply bg-background text-foreground;
	}
}

.fserv-container .powered-by {
	display: none !important;
}

/* About page specific styles */
.about-hero {
	background-image: url('/grid-pattern.svg');
	background-size: cover;
	background-position: center;
}

.solution-card {
	transition: all 0.3s ease;
}

.solution-card:hover {
	transform: translateY(-5px);
	box-shadow:
		0 20px 25px -5px rgba(0, 0, 0, 0.1),
		0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

.stat-number {
	background: linear-gradient(135deg, #2563eb, #4f46e5);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

/* Leadership card hover effect */
.leadership-card {
	transition: all 0.3s ease;
}

.leadership-card:hover {
	transform: translateY(-5px) rotate(1deg);
}

.feature-card {
	transition: transform 0.2s ease-in-out;
}

.feature-card:hover {
	transform: translateY(-5px);
}

@keyframes float {
	0%,
	100% {
		transform: translateY(0) scale(1);
		opacity: 0.3;
	}
	50% {
		transform: translateY(-20px) scale(1.1);
		opacity: 0.6;
	}
}

.bg-grid-pattern {
	background-image:
		linear-gradient(
			to right,
			rgba(255, 255, 255, 0.1) 1px,
			transparent 1px
		),
		linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0.1) 1px,
			transparent 1px
		);
	background-size: 30px 30px;
}

.animate-pulse-slow {
	animation: pulse 4s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
	0%,
	100% {
		opacity: 0.2;
	}
	50% {
		opacity: 0.4;
	}
}

/* Add more custom animations as needed */

/* Custom styles for the NavigationMenu */
.NavigationMenuContent {
	animation-duration: 200ms;
	animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
	will-change: transform, opacity;
}

.NavigationMenuContent[data-motion='from-start'] {
	animation-name: enterFromLeft;
}

.NavigationMenuContent[data-motion='from-end'] {
	animation-name: enterFromRight;
}

.NavigationMenuContent[data-motion='to-start'] {
	animation-name: exitToLeft;
}

.NavigationMenuContent[data-motion='to-end'] {
	animation-name: exitToRight;
}

@keyframes enterFromRight {
	from {
		opacity: 0;
		transform: translateX(10px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes enterFromLeft {
	from {
		opacity: 0;
		transform: translateX(-10px);
	}
	to {
		opacity: 1;
		transform: translateX(0);
	}
}

@keyframes exitToRight {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(10px);
	}
}

@keyframes exitToLeft {
	from {
		opacity: 1;
		transform: translateX(0);
	}
	to {
		opacity: 0;
		transform: translateX(-10px);
	}
}

/* Modern menu styling - enhanced */
.menu-content-wrapper {
	position: relative;
	overflow: hidden;
	border-radius: 0.5rem;
	box-shadow:
		0 10px 25px -5px rgba(0, 0, 0, 0.1),
		0 8px 10px -6px rgba(0, 0, 0, 0.05);
	transition: all 0.2s ease;
	max-width: 90vw;
	z-index: 50;
	max-height: 80vh;
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: rgba(239, 68, 68, 0.3) transparent;
}

.menu-content-wrapper::-webkit-scrollbar {
	width: 4px;
}

.menu-content-wrapper::-webkit-scrollbar-track {
	background: transparent;
}

.menu-content-wrapper::-webkit-scrollbar-thumb {
	background-color: rgba(239, 68, 68, 0.3);
	border-radius: 20px;
}

.menu-content-wrapper::before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(
		to bottom right,
		rgba(255, 255, 255, 0.95),
		rgba(255, 255, 255, 0.98)
	);
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	border-radius: 0.5rem;
	z-index: -1;
}

/* Enhanced animations */
@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translateY(5px);
	}
	to {
		opacity: 1;
		transform: translateY(0);
	}
}

/* Improved focus styles */
.NavigationMenuTrigger:focus,
.NavigationMenuLink:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

/* Desktop menu optimizations */
.NavigationMenuTrigger {
	position: relative;
}

.menu-trigger::after {
	content: '';
	position: absolute;
	left: 50%;
	bottom: 0;
	height: 2px;
	width: 0;
	background-color: rgb(239, 68, 68);
	transition: all 0.2s ease;
	transform: translateX(-50%);
}

.NavigationMenuTrigger[data-state='open']::after {
	width: 30%;
}

/* Mobile menu optimizations */
@media (max-width: 1023px) {
	.mobile-menu-wrapper {
		max-height: 70vh;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		scrollbar-width: thin;
		scrollbar-color: rgba(239, 68, 68, 0.3) transparent;
	}

	.mobile-menu-wrapper::-webkit-scrollbar {
		width: 4px;
	}

	.mobile-menu-wrapper::-webkit-scrollbar-track {
		background: transparent;
	}

	.mobile-menu-wrapper::-webkit-scrollbar-thumb {
		background-color: rgba(239, 68, 68, 0.3);
		border-radius: 20px;
	}

	.mobile-accordion-content {
		padding-left: 0.5rem;
	}

	.mobile-menu-link {
		transition: background-color 0.15s ease;
	}

	.mobile-menu-link:active {
		background-color: rgba(239, 68, 68, 0.1);
	}
}

/* Touch-friendly targets */
@media (max-width: 640px) {
	.mobile-menu-link {
		padding: 12px;
		min-height: 44px;
	}

	.accordion-trigger {
		min-height: 44px;
	}
}

/* Improved focus styles for accessibility */
.NavigationMenuLink:focus-visible,
.NavigationMenuTrigger:focus-visible,
.accordion-trigger:focus-visible,
.mobile-menu-link:focus-visible {
	outline: 2px solid rgba(239, 68, 68, 0.4);
	outline-offset: 2px;
}

/* Smooth transitions */
.NavigationMenuContent,
.NavigationMenuTrigger,
.NavigationMenuLink,
.accordion-trigger,
.mobile-menu-link {
	transition: all 0.2s ease;
}

/* Optimized animations */
@keyframes fadeInScale {
	from {
		opacity: 0;
		transform: scale(0.98) translateY(-4px);
	}
	to {
		opacity: 1;
		transform: scale(1) translateY(0);
	}
}

.NavigationMenuContent {
	animation: fadeInScale 0.15s ease;
	transform-origin: top center;
	overflow: visible !important;
}

/* Badge animations - enhanced */
@keyframes pulse {
	0% {
		opacity: 0.6;
		transform: scale(0.95);
	}
	70% {
		opacity: 1;
		transform: scale(1.05);
	}
	100% {
		opacity: 0.6;
		transform: scale(0.95);
	}
}

.badge-new {
	animation: pulse 2s ease-in-out infinite;
	position: relative;
	overflow: hidden;
}

.badge-new::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.5) 50%,
		rgba(255, 255, 255, 0) 100%
	);
	transform: translateX(-100%);
	animation: shimmer 2s infinite;
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}

/* Add tooltip styling for the green dots */
[title] {
	position: relative;
}

[title]:hover::after {
	content: attr(title);
	position: absolute;
	bottom: 100%;
	left: 50%;
	transform: translateX(-50%);
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	font-size: 10px;
	padding: 2px 6px;
	border-radius: 4px;
	white-space: nowrap;
	pointer-events: none;
	opacity: 0;
	animation: fadeIn 0.2s ease forwards;
	z-index: 100;
}

/* New feature dot animation */
@keyframes ping {
	75%,
	100% {
		transform: scale(1.5);
		opacity: 0;
	}
}

.animate-ping {
	animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
}

/* Scrollbar styling for horizontal scrolling elements */
.scrollbar-thin {
	scrollbar-width: thin;
}

.scrollbar-thin::-webkit-scrollbar {
	height: 4px;
}

.scrollbar-thin::-webkit-scrollbar-track {
	background: transparent;
}

.scrollbar-thin::-webkit-scrollbar-thumb {
	background-color: rgba(229, 231, 235, 0.8);
	border-radius: 20px;
}

.scrollbar-thin::-webkit-scrollbar-thumb:hover {
	background-color: rgba(209, 213, 219, 0.8);
}

/* Improved focus states for accessibility */
a:focus-visible,
button:focus-visible {
	outline: 2px solid rgba(239, 68, 68, 0.4);
	outline-offset: 2px;
}

/* Improved transitions for interactive elements */
a,
button,
.menu-trigger,
.NavigationMenuTrigger,
.NavigationMenuLink {
	transition: all 0.15s ease;
}

@keyframes scroll {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

.marquee-wrapper {
	width: 100%;
	overflow: hidden;
}

.marquee {
	display: flex;
	animation: scroll 30s linear infinite;
	width: max-content;
	gap: 0.25rem; /* Small gap between items */
}

.marquee-item {
	flex: 0 0 auto;
	display: flex;
	align-items: center;
}

.marquee-wrapper:hover .marquee {
	animation-play-state: paused;
}
